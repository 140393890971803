import { preferenceSitesSchema } from "@amedia/beethoven-schemas";
import { z } from "zod";
import useSWR from "swr";

import { fetcher } from "./fetcher";
import { FetcherError } from "./errors";

const ALT_BASE_PATH = "https://www.alt.no";

// Note: When upgrading zod to latest version, inferring the type from the schema might break as
// the zod version in this package does not match the one in the beethoven-schemas package.
// Ideally, beethoven-schemas should export the type inferred from the schema directly?
type ClientConfigSiteArray = z.infer<typeof preferenceSitesSchema>;

export type ClientConfigSite = ClientConfigSiteArray[0];

type Result = {
  sites: ClientConfigSite[];
  error?: FetcherError | Error;
};

export const usePublicationsList = (): Result => {
  const url = `${ALT_BASE_PATH}/api/beethoven/v1/config/preference-sites?sort=asc`;

  const fetchOptions = {
    method: "GET",
  };

  const { data, error } = useSWR<ClientConfigSite>(
    [url, fetchOptions],
    fetcher,
  );

  return {
    sites: data ? data : [],
    ...(error && { error }),
  };
};
