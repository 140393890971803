import { useState } from "react";
import { styled } from "styled-components";

import { SubscriptionInfoCard } from "./subscriptionInfoCard";
import { hundrePlussAviserButton } from "./utils";

import { usePublicationsList } from "@/fetch/publicationList";
import { useTranslate } from "@/i18n";
import BrickIcon from "@/public/icons/BrickIcon";

const ExpandButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  cursor: pointer;
  width: 100%;
  background: transparent;
  border: 1px solid transparent;
`;

const Divider = styled.div<{ expanded: boolean }>`
  border: 1px solid var(--brick-colors-baseNeutral200);
`;

const CountyTitle = styled.p`
  color: var(--brick-colors-baseNeutral600);
  font-family: var(--brick-fonts-baseHeadlineXs);
  font-size: var(--brick-fontSizes-baseHeadlineXs);
  font-weight: var(--brick-fontWeights-baseHeadlineXs);
  letter-spacing: var(--brick-letterSpacings-baseHeadlineXs);
  line-height: var(--brick-lineHeights-baseHeadlineXs);
`;

const NewspapersName = styled.p`
  font-family: var(--brick-fonts-baseMetaXs);
  font-size: var(--brick-fontSizes-baseMetaXs);
  font-weight: var(--brick-fontWeights-baseMetaXs);
  letter-spacing: var(--brick-letterSpacings-baseMetaXs);
  line-height: var(--brick-lineHeights-baseMetaXs);
  color: var(--brick-colors-baseNeutral600);
  margin: 8px;
`;

type Newspaper = {
  name: string;
  county?: string;
};

type GroupedByCounty = {
  [county: string]: string[];
};

export const HundrePlussAviser = () => {
  const [expandCounty, setExpandCounty] = useState<string | null>(null);
  const t = useTranslate(hundrePlussAviserText);

  const { sites } = usePublicationsList();

  const newspapers: Newspaper[] = sites.map((s) => ({
    name: s.name.short,
    county: s.location.county,
  }));

  const onExpand = (county: string) => {
    setExpandCounty(expandCounty === county ? null : county);
  };

  const groupByCounty = (data: Newspaper[]): GroupedByCounty => {
    return data.reduce((acc: GroupedByCounty, item) => {
      const countyKey = item.county || "Nasjonalt";

      if (!acc[countyKey]) {
        acc[countyKey] = [];
      }
      acc[countyKey].push(item.name);
      return acc;
    }, {});
  };

  const groupedNewspapers = groupByCounty(newspapers);

  const CountyContainer = styled.div`
    gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  `;

  // TODO, when a section is expanded, the same button should still have focus
  // rerendering issue?
  return (
    <SubscriptionInfoCard
      id="plussalt"
      title={t("title")}
      type="hundrePlussAviser"
      description={[t("plussAltDescription")]}
      buttons={hundrePlussAviserButton}
      expandedContent={
        <div style={{ marginBottom: 24 }}>
          {Object.entries(groupedNewspapers).map(([county, names]) => (
            <>
              <CountyContainer key={county}>
                <ExpandButton onClick={() => onExpand(county)}>
                  <CountyTitle>{county}</CountyTitle>
                  <BrickIcon
                    iconId={
                      expandCounty === county ? "chevron-up" : "chevron-down"
                    }
                    width="24px"
                    height="24px"
                  />
                </ExpandButton>
              </CountyContainer>
              {expandCounty === county &&
                names.map((name, index) => (
                  <NewspapersName key={index}>{name}</NewspapersName>
                ))}
              <Divider expanded={expandCounty === county} />
            </>
          ))}
        </div>
      }
    />
  );
};
export default HundrePlussAviser;

const hundrePlussAviserText = {
  title: {
    nb: "Du har over 100 aviser å velge mellom",
    nn: "Du har over 100 aviser å velje mellom",
  },
  plussAltDescription: {
    nb: "+Alt gir deg tilgang til aviser og nyheter fra hele Norge. Avisene kan leses både på nett, i app og gjennom eAviskiosken. ",
    nn: "+Alt gir deg tilgang til aviser og nyheiter frå heile Noreg. Avisene kan leses på nett, i app og gjennom eAviskiosken.",
  },

  plussaltTitlePointOne: {
    nn: "Hvilke aviser og hvilke områder dekkes av +Alt?",
    nb: "Hvilke aviser og hvilke områder dekkes av +Alt?",
  },
  plussAltDescriptionPointOne: {
    nb: "I kartet ser du hvilke områder som dekkes av avisene som er med i +Alt.",
    nn: "I kartet ser du hvilke områder som dekkes av avisene som er med i +Alt.",
  },
  helperText: {
    nb: "Last ned appen for iOS eller Android",
    nn: "Last ned appen for iOS eller Android",
  },
};
